import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'What we cover',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image primary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-01.svg')}
                        alt="Features tile icon 01"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-left" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Award Ceremonies
                    </h4>
                    <p className="m-0 text-sm reveal-from-left" data-reveal-delay="400" data-reveal-container=".tiles-item">
                    Filming your award ceremonies is a great way to capture the glitz, elegance, and prestige of such day. Award ceremony videos are ideal for keeping a lasting record your accomplishments. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image secondary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-02.svg')}
                        alt="Features tile icon 02"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-bottom" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Conferences
                    </h4>
                    <p className="m-0 text-sm reveal-from-bottom" data-reveal-delay="400" data-reveal-container=".tiles-item">
                    A professional video of your conference or speaking event can provide a thorough picture of the topics you want to share with those who were unable to attend and can be used subsequently.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image primary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-03.svg')}
                        alt="Features tile icon 03"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Training Presentations
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-delay="400" data-reveal-container=".tiles-item">
                     Training presentation event filming enables your company to share educational content and explainer videos internally or externally with potential customers.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image secondary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-04.svg')}
                        alt="Features tile icon 04"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-left" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Grand Openings & Unveiling
                    </h4>
                    <p className="m-0 text-sm reveal-from-left" data-reveal-delay="400" data-reveal-container=".tiles-item">
                    If you are planning an event to promote the grand opening of a new company, an additional branch, showroom, or property then it’s definitely an event worth filming.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image primary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-05.svg')}
                        alt="Features tile icon 05"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-bottom" data-reveal-delay="200" data-reveal-container=".tiles-item">
                     Music Festivals
                    </h4>
                    <p className="m-0 text-sm reveal-from-bottom" data-reveal-delay="400" data-reveal-container=".tiles-item">
                    Live event filming & streaming for music festivals is a great way to promote your event on your website or social media and generate buzz for your that event and the next one.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image secondary-color mb-16 reveal-rotate-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-06.svg')}
                        alt="Features tile icon 06"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-delay="200" data-reveal-container=".tiles-item">
                      Charity Events
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-delay="400" data-reveal-container=".tiles-item">
                     What is your company doing to raise money for charitable causes? Share it with the world through video. Whether you are holding an event to raise money, or taking part in a viral challenge.
                    </p>
                  </div>
                </div>
              </div>              

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;