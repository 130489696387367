import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import TextEffect from '../elements/TextEffect';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults 
}

class HeroFull extends React.Component {

   state = {
    videoModalActive: false
   }
  
    openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }  

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
           
              <h1 className="mt-0 mb-16 reveal-from-top" data-reveal-delay="300">
               Welcome to Nicosoft Media
              </h1>
              <div className="container-xs">
               
                
                <div className="reveal-from-top" data-reveal-delay="500">
                  
                   <h4 className="font-link mt-0 mb-32 text-1xl  leading-relaxed wow animate__animated animate__fadeIn" data-reveal-delay="400">
                                   At <strong className="text-blue-500">Nicosoft</strong>, we are specialist in {" "}
                                    <strong className="typewrite d-inline ">
                                       <TextEffect text1="Fashion shows" text2="Videography" text3="Animation"  text4="Streaming" text5="Advertising" text6="Press Conference" text7="Photography" />
                                   </strong> 
                                </h4>
                  <Button tag="a" color="primary" href="/" wideMobile>
                    About Us
                  </Button>
                </div>
              </div>
            </div>
            <div className="hero-figure illustration-element-01 reveal-from-top" data-reveal-delay="500">
             
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.png')}
                width={896}
                height={504}
                  alt="Hero" />
             
                <a
              data-video="https://christfaithmedia.co/nicosoftmtv/"
              href="#0"
              aria-controls="video-modal"
              onClick={this.openVideoModal}
            >
                <div style={imgStyle}>
                  <Image
                    src={require('./../../assets/images/hero-image-top.png')}
                    width={896}
                    height={504}
                    alt="Hero top" />
              </div></a>
               <Modal
              id="video-modal"
              show={this.state.videoModalActive}
              handleClose={this.closeVideoModal}
              video="https://iframe.dacast.com/live/5919e332-e0d1-d1e0-9b38-609bae06e646/e9c22490-996e-baac-be77-695f615f3243"
              videoTag="iframe" />
              
            </div>
           
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: 'absolute',
  left: '-5.9%',
  right: '5.9%',
  top: '-12.74%'
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;