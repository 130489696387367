import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../utils/SectionProps';
// import SectionHeader from '../components/sections/partials/SectionHeader';
import Image from '../components/elements/Image';
import HeroSplit from '../components/sections/HeroSplit';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class Gallery extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    // const sectionHeader = {
    //   title: 'Bold startup toolkit',
    //   paragraph: 'Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.'
    // };

    return (
      <React.Fragment>
           <HeroSplit hasBgColor imageFill className="illustration-section-01" />
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <div className={splitClasses}>

              <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-03',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/3T2A6613.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/3T2A3764.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-05',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/DSC08715.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
                </div>
                
                   <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/3T2A3527.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
                </div>


                 <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-03',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/DSC00143.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
                </div>
                
                 <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/3T2A3602.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
                </div>


                  <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-03',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/DSC08769.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
                </div>


                     <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/3T2A3764.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
                </div>



                  <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  {/* <h3 className="mt-0 mb-16">
                    Lorem ipsum dolor sit
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-03',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../assets/images/gallery/3T2A6620.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
                </div>

            </div>
          </div>
        </div>
        </section>
        </React.Fragment>
    );
  }
}

Gallery.propTypes = propTypes;
Gallery.defaultProps = defaultProps;

export default Gallery;